*{
  box-sizing: border-box;
}
body{
  margin: 0;
  padding: 0;
  font-family: regular;
  background-color: #FFFCF4;
}

h1,h2,h3,h4,h5,h6,p{
  margin:0;
}

button,input, select {
  outline:none !important;
}
:where(.css-dev-only-do-not-override-ixblex).ant-picker {
  width: 100%;
  padding: 16px;
  border-radius: 0px !important;
}
@font-face {
  font-family: "Bold";
  src: local("AtypDisplay-Bold"),
    url("./fonts/AtypDisplay-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Light";
  src: local("AtypText-Light"),
    url("./fonts/AtypText-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Regular";
  src: local("AtypText-Regular"),
    url("./fonts/AtypText-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Medium";
  src: local("AtypText-Semibold"),
    url("./fonts/AtypText-Semibold.ttf") format("opentype");
}

.ant-modal-content{
  height:auto !important;
}

.ant-dropdown-menu {
  width: auto;
  border-radius: 30px !important;
  padding: 20px 10px !important;
  z-index:9999999;
  margin: auto;
}
.ant-dropdown-menu-item:hover {
  background-color: #fff !important;
  cursor: auto;
  z-index:9999999;
}

.dzYLcH{
  padding:0 !important;
}

.ant-collapse{
  font-family: Medium;
  background-color: transparent !important;
  border: none;
  
}

.ant-collapse p{
  font-family: Regular;
}

.ant-collapse p li{
 list-style-type: circle;
}

.ant-collapse-expand-icon {
 display: block !important;
}

.ant-modal-mask{
  background: rgba(0, 0, 0, 0.85) !important;
}

.ant-picker {
  height:60px !important;
  width:100% !important;
}

@media (max-width: 800px) {
.ant-modal-content {
  padding: 10px !important;
}
}

