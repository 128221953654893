.banner-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner-image img {
    width: 100%;
    height: 100%;
}

.banner-image-mobile {
    width: 100%;
    height: 100%;
}

.banner-image-mobile img {
    width: 100%;
    height: 100%;
}

/* Hide mobile banner on desktop */
@media (min-width: 768px) {
    .banner-image-mobile {
        display: none;
    }
}



.bg-black {
    background-color: black;
    border-radius: 30px;
}

.bg-black .ant-modal-content {
    background-color: black;
    border-radius: 30px;
    padding: 10px 60px 40px 60px;
}

.bg-black .ant-modal-content .ant-modal-close {
    left: 20px;
    padding: 12px;
    width: 45px;
    background-color: #f5f5f5;
    color: #525252;
    height: 45px;
    border-radius: 50%;
}

.banner-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

}
.button-arrow {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    text-align: center;
    justify-content: center;
}
/* Hide desktop banner on mobile */
@media (max-width: 767px) {
    .banner-image {
        display: none;
    }

    .bg-black .ant-modal-content {

        padding: 10px 0px 20px 20px !important;
    }

    .banner-text {
        margin-top: 10px;
    }

    .bg-black .ant-modal-content .ant-modal-close {
        left: 10px;
        padding: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
}